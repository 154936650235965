






























































































































import { ref, watchEffect, computed } from '@vue/composition-api';
import axios from 'axios';

export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any) {
    const successMsg = ref(false);
    const errorMsg = ref(false);
    const msg = ref('');
    const TODO = ref(true);
    const TADA = ref(false);
    const isCreating = ref(false);
    const priorities = ref(['High', 'Medium', 'Low']);
    const difficulties = ref(['Easy', 'Medium', 'Hard']);
    const taskItem: any = ref({
      isCompleted: false,
      name: '',
      priority: '',
      difficulty: ''
    });
    const taskTable: any = ref([]);
    const headers = [
      { text: 'Name', value: 'name', width: '80%' },
      { text: 'Priority', value: 'priority', width: '10%' },
      { text: 'Difficulty', value: 'difficulty', width: '10%' }
    ];

    const taskList: any = computed(() => {
      const priorityMap = { High: 0, Medium: 1, Low: 2 };
      return taskTable.value
        .slice()
        .sort((a, b) => priorityMap[a.priority] - priorityMap[b.priority]);
    });

    const todoList: any = computed(() => {
      return taskList.value?.filter(t => !t.isCompleted);
    });

    const tadaList = computed(() => {
      return taskList.value?.filter(t => t.isCompleted);
    });

    const isDiffColor = value => {
      switch (value) {
        case 'Easy':
          return 'green';
        case 'Medium':
          return 'yellow';
        case 'Hard':
          return 'orange';
        default:
          return '';
      }
    };

    const isPriorityColor = value => {
      switch (value) {
        case 'Low':
          return 'orange';
        case 'Medium':
          return 'yellow';
        case 'High':
          return 'green';
        default:
          return '';
      }
    };

    async function saveProjectTasks() {
      isCreating.value = true;
      try {
        const data = {
          projectTasks: taskTable.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isCreating.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isCreating.value = false;
      }
    }

    async function populateTask() {
      if (taskItem.value.name && taskItem.value.priority && taskItem.value.difficulty) {
        taskTable.value.push(taskItem.value);
        taskItem.value = {
          isCompleted: false,
          name: '',
          priority: '',
          difficulty: ''
        };
        await saveProjectTasks();
      }
    }

    watchEffect(() => {
      if (props.data.projectTasks) {
        taskTable.value = props.data.projectTasks;
      }
    });

    return {
      successMsg,
      errorMsg,
      msg,
      isCreating,
      populateTask,
      difficulties,
      priorities,
      taskItem,
      headers,
      taskTable,
      saveProjectTasks,
      taskList,
      isDiffColor,
      isPriorityColor,
      TODO,
      TADA,
      todoList,
      tadaList
    };
  }
};
