var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex justify-start ml-7"},[_c('v-btn',{staticClass:"mr-2 mb-3 mt-6",attrs:{"dark":_vm.TODO ? true : false,"outlined":!_vm.TODO ? true : false,"depressed":""},on:{"click":function($event){_vm.TODO = true;
        _vm.TADA = false;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bullseye")]),_vm._v("TODO")],1),_c('v-btn',{staticClass:"ml-2 mb-3 mt-6",attrs:{"dark":_vm.TADA ? true : false,"outlined":!_vm.TADA ? true : false,"depressed":""},on:{"click":function($event){_vm.TADA = true;
        _vm.TODO = false;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bullseye-arrow")]),_vm._v("TADA!")],1)],1),_c('v-data-table',{staticClass:"pa-0 ma-0",attrs:{"headers":_vm.headers,"items":_vm.TODO ? _vm.todoList : _vm.tadaList,"item-key":"name","show-select":_vm.taskList.length ? true : false,"single-select":"","items-per-page":50},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"id":item.name,"disabled":_vm.viewOnly},on:{"click":_vm.saveProjectTasks},model:{value:(item.isCompleted),callback:function ($$v) {_vm.$set(item, "isCompleted", $$v)},expression:"item.isCompleted"}})]}},{key:"item.difficulty",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text font-weight-bold",attrs:{"label":"","outlined":"","color":_vm.isDiffColor(item.difficulty)}},[_vm._v(" "+_vm._s(item.difficulty)+" ")])]}},{key:"item.priority",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text font-weight-bold",attrs:{"label":"","color":_vm.isPriorityColor(item.priority)}},[_vm._v(" "+_vm._s(item.priority)+" ")])]}}],null,true)}),_c('div',{staticClass:"pa-6 pb-12"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"module-edit__inputs-video"},[_c('v-text-field',{staticClass:"student-research-link-input",attrs:{"readonly":_vm.viewOnly,"label":"Enter Task","outlined":"","rounded":""},model:{value:(_vm.taskItem.name),callback:function ($$v) {_vm.$set(_vm.taskItem, "name", $$v)},expression:"taskItem.name"}})],1),_c('div',{staticClass:"module-edit__inputs-video ml-2"},[_c('v-select',{attrs:{"id":"priority","items":_vm.priorities,"readonly":_vm.viewOnly,"rounded":"","outlined":"","label":"Priority"},model:{value:(_vm.taskItem.priority),callback:function ($$v) {_vm.$set(_vm.taskItem, "priority", $$v)},expression:"taskItem.priority"}})],1),_c('div',{staticClass:"module-edit__inputs-video ml-2"},[_c('v-select',{attrs:{"id":"difficulty","items":_vm.difficulties,"readonly":_vm.viewOnly,"rounded":"","outlined":"","label":"Difficulty"},model:{value:(_vm.taskItem.difficulty),callback:function ($$v) {_vm.$set(_vm.taskItem, "difficulty", $$v)},expression:"taskItem.difficulty"}})],1)]),_c('div',{staticClass:"module-edit__add"},[_c('v-btn',{staticClass:"module-edit__add-button white--text font-weight-bold",attrs:{"x-large":"","disabled":_vm.viewOnly,"loading":_vm.isCreating,"rounded":"","depressed":"","color":"green"},on:{"click":_vm.populateTask}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-plus")]),_vm._v(" Add Task ")],1)],1),(_vm.successMsg || _vm.errorMsg)?_c('v-alert',{staticClass:"mt-3 white--text presets__alert",attrs:{"color":_vm.successMsg ? 'green' : 'red',"dense":""}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }